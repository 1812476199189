import React, { useEffect, useMemo, useState } from "react";

import { Transition } from "@headlessui/react";
import { CellContext, Table, createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { HiEye } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { groupBy, pick } from "remeda";
import { useDebounce } from "use-debounce";

import AccountStatusFilter from "@app/modules/invitation/filters/account-status-filter";
import {
  filterInvitations,
  useInvitationFilter,
} from "@app/modules/invitation/filters/hook";
import InvitationStatusFilter from "@app/modules/invitation/filters/invitation-status-filter";
import KeyAccountFilter from "@app/modules/invitation/filters/key-account-filter";
import MarketFilter from "@app/modules/invitation/filters/market-filter";
import PortfolioFilter from "@app/modules/invitation/filters/portfolio-filter";
import WildcardFilter from "@app/modules/invitation/filters/wildcard-filter";
import MRTable from "@components/data-display/MRTable";
import Tag, { TagProps } from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import Checkbox from "@components/data-entry/Checkbox";
import SingleSelect from "@components/data-entry/SingleSelect";
import LoadingFetch from "@components/feedback/LoadingFetch";
import { arrayFilterDuplicateIds, arrayFilterUnique } from "@helpers/Array";
import { usePrefixedTranslation } from "@helpers/Translation";
import { AccountStatus } from "@models/Account";
import {
  AccountsStatusColors,
  InvitationStatusEnum,
  MailDeliveryStatusEnum,
  MarketType,
  ShowroomSeason,
} from "@models/types/enums";
import { GetContactsEndpoint } from "@services/api/contacts/get-contacts";
import { GetInvitations } from "@services/api/sales-campaigns/get-invitations";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { AccessControl } from "@shared/components/access-control";
import { fullName } from "@shared/helpers/formatters";
import {
  translateAccountStatus,
  translateInvitationStatus,
  translateMailDeliveryStatus,
} from "@shared/helpers/translater";
import SendFollowupsCTA from "@shared/invitations/cta/send-follow-ups";
import SendInvitationsCTA from "@shared/invitations/cta/send-invitations";
import { getLatestEmailTracking } from "@shared/invitations/helper";
import NoOngoingShowroom from "@shared/no-ongoing-showroom";

interface ContactRow {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  position: string | null;
  markets: MarketType[];
  portfolios: {
    id: string;
  }[];
  account: {
    name: string;
    collectionInformationByAccounts: {
      collectionId: string;
      status: AccountStatus;
    }[];
  };
  invitationsPerShowroom: Record<
    string,
    | { id: string; status: string; emailStatus: string; invitationId?: string }
    | undefined
  >;
}

class SeasonYear {
  static toString(season: ShowroomSeason, year: number) {
    return `${season} ${year}`;
  }

  static fromString(seasonYearStr: string) {
    const [season, year] = seasonYearStr.split(" ");
    return { season: season as ShowroomSeason, year: parseInt(year, 10) };
  }
}

function createStatusesCellRenreder(showroom: {
  collections: { id: string }[];
}) {
  const showroomCollectionIds = showroom.collections.map((c) => c.id);
  return function StatusesCell({ row }: CellContext<ContactRow, unknown>) {
    const { t } = useTranslation();
    const accountShowroomStatuses =
      row.original.account.collectionInformationByAccounts.filter((i) =>
        showroomCollectionIds.includes(i.collectionId),
      );

    const collectionsByStatus = groupBy(
      accountShowroomStatuses,
      (i) => i.status,
    );

    return (
      <div>
        {Object.entries(collectionsByStatus).map(([status, collections]) => (
          <Tag
            withCount={collections.length}
            key={status}
            theme={AccountsStatusColors[status as AccountStatus]}
            type="dotted"
          >
            {t(translateAccountStatus(status as AccountStatus))}
          </Tag>
        ))}
      </div>
    );
  };
}

function createInvitationCellRenderer(showroomId: string) {
  const themeByInvitationStatus: Record<
    InvitationStatusEnum,
    TagProps["theme"]
  > = {
    [InvitationStatusEnum.NOT_INVITED]: "GREY",
    [InvitationStatusEnum.INVITED]: "ORANGE",
    [InvitationStatusEnum.FOLLOWED_UP]: "ORANGE",

    [InvitationStatusEnum.FAILED]: "RED",
    [InvitationStatusEnum.CANCELLED]: "RED",
    [InvitationStatusEnum.CANCELLED_BY_ORGANIZATION]: "RED",

    [InvitationStatusEnum.JOKER]: "PURPLE",

    [InvitationStatusEnum.BOOKED]: "GREEN",
    [InvitationStatusEnum.BOOKED_BY_ORGANIZATION]: "GREEN",
  };
  return function InvitationCell({ row }: CellContext<ContactRow, unknown>) {
    const { t } = useTranslation();
    const invitation = row.original.invitationsPerShowroom[showroomId];

    const invitationStatus: InvitationStatusEnum = invitation
      ? (invitation.status as InvitationStatusEnum)
      : InvitationStatusEnum.NOT_INVITED;

    return (
      <div>
        <Tag
          type="dotted"
          size="sm"
          theme={themeByInvitationStatus[invitationStatus]}
        >
          {t(translateInvitationStatus(invitationStatus))}
        </Tag>
      </div>
    );
  };
}

function createEmailCellRenderer(showroomId: string) {
  return function EmailCell({ row }: CellContext<ContactRow, unknown>) {
    const { t } = useTranslation();
    const invitation = row.original.invitationsPerShowroom[showroomId];
    const text =
      invitation && invitation.emailStatus !== MailDeliveryStatusEnum.NOT_SENT
        ? t(
            translateMailDeliveryStatus(
              invitation.emailStatus as MailDeliveryStatusEnum,
            ),
          )
        : "-";
    return (
      <div>
        <div>{text}</div>
      </div>
    );
  };
}

function createCheckboxColumnHeaderRenderer({
  table,
}: {
  table: Table<ContactRow>;
}) {
  return table.getRowModel().rows.some((r) => r.getCanSelect()) ? (
    <Checkbox
      aria-label="select/deselect all rows"
      name="all-accounts"
      id="select-all-accounts"
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  ) : null;
}

const nameEmailCellRenderer = ({ row }: CellContext<ContactRow, unknown>) => (
  <Tooltip content={row.original.email}>{fullName(row.original)}</Tooltip>
);

function createCheckboxColumnRenderer({
  id,
  name,
  label,
}: {
  id: string;
  name: string;
  label: string;
}) {
  return function CheckboxColumn({ row }: CellContext<ContactRow, unknown>) {
    return (
      <Checkbox
        id={id}
        name={name}
        value={row.original.id}
        disabled={!row.getCanSelect()}
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
        aria-label={`${row.getIsSelected() ? "unselect" : "select"} ${label}`}
      />
    );
  };
}

function createLinkCellRenderer(showroomId: string) {
  return function LinkCell({ row }: CellContext<ContactRow, unknown>) {
    const invitationId =
      row.original.invitationsPerShowroom[showroomId]?.invitationId;
    return invitationId ? (
      <AccessControl useImpersonatedUser={false} roles={[]}>
        <Link to={`/booking/${invitationId}`}>
          <HiEye className="w-4 h-4" />
        </Link>
      </AccessControl>
    ) : null;
  };
}

export default function InvitationsPage() {
  const { pt } = usePrefixedTranslation("Invitation.InvitationsPage");
  const [selectedContactIds, setSelectedContactIds] = useState<string[]>([]);
  const filterState = useInvitationFilter();
  const [showFilters, setShowFilters] = useState(false);
  const [{ season, year }, setSeasonYear] = useState<{
    season: ShowroomSeason;
    year: number;
  }>({
    season: "SS",
    year: 2021,
  });
  const { organization } = useOrganizationAppContext();
  const {
    data: allOngoingShowrooms = [],
    status: fetchAllOngoingShowroomsStatus,
  } = GetOngoingShowroomsEndpoint.useHook({
    organizationId: organization.id,
  });
  const { data: contacts = [] } = GetContactsEndpoint.useHook({
    organizationId: organization.id,
  });
  const { data: invitations = [], status: invitationFetchStatus } =
    GetInvitations.useHook({
      organizationId: organization.id,
      season,
      year,
    });

  // once showrooms are fetched set to the first showroom's season and year
  useEffect(() => {
    if (allOngoingShowrooms.length > 0) {
      setSeasonYear(pick(allOngoingShowrooms[0], ["season", "year"]));
    }
  }, [allOngoingShowrooms]);

  // filter showrooms by season and year
  const seasonOngoingShowrooms = allOngoingShowrooms.filter(
    (s) => s.season === season && s.year === year,
  );
  const collections = seasonOngoingShowrooms
    .flatMap((s) => s.collections)
    .filter(arrayFilterDuplicateIds);

  // add invitations to contacts
  const contactsWithAggregation = contacts.map((c) => ({
    ...c,
    account: {
      ...c.account,
      collectionInformationByAccounts: collections.map((collection) => {
        const matchingCollectionStatus =
          c.account.collectionInformationByAccounts.find(
            (i) => i.collectionId === collection.id,
          );
        return (
          matchingCollectionStatus || {
            collectionId: collection.id,
            status: "PROSPECT" as AccountStatus,
          }
        );
      }),
    },
    invitationsPerShowroom: seasonOngoingShowrooms.reduce(
      (acc, showroom) => {
        const invitation = invitations.find(
          (i) => i.contactId === c.id && i.showroomId === showroom.id,
        );
        acc[showroom.id] = invitation
          ? {
              ...invitation,
              status: invitation.invitationStatus,
              emailStatus: getLatestEmailTracking(invitation.emailTrackings)
                .status,
            }
          : {
              id: `${showroom.id}-${c.id}`,
              status: InvitationStatusEnum.NOT_INVITED,
              emailStatus: MailDeliveryStatusEnum.NOT_SENT,
            };
        return acc;
      },
      {} as Record<
        string,
        | {
            id: string;
            status: string;
            emailStatus: string;
            invitationId?: string;
          }
        | undefined
      >,
    ),
  }));
  const [debouncedFilters] = useDebounce(filterState.values, 500, {
    // the equality function prevents the debounce from triggering a rerender if the filters are the same
    equalityFn: (a, b) =>
      a.accountStatus === b.accountStatus &&
      a.collectionId === b.collectionId &&
      a.invitationStatus === b.invitationStatus &&
      a.market === b.market &&
      a.portfolioId === b.portfolioId &&
      a.wildcard === b.wildcard &&
      a.isKeyAccountFilter === b.isKeyAccountFilter &&
      a.showroomId === b.showroomId,
  });

  const filteredContacts = filterInvitations(
    contactsWithAggregation,
    debouncedFilters,
  );

  const columnHelper = createColumnHelper<ContactRow>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: (arg) => createCheckboxColumnHeaderRenderer(arg),
        cell: (cell) =>
          createCheckboxColumnRenderer({
            id: `select-contact-${cell.row.original.id}`,
            name: `select-contact-${cell.row.original.id}`,
            label: fullName(cell.row.original),
          })(cell),
      }),
      columnHelper.display({
        id: "contact-name-email",
        header: "Name",
        cell: nameEmailCellRenderer,
      }),
      columnHelper.display({
        id: "account-name",
        header: "Account",
        cell: ({ row }) => row.original.account.name,
      }),
      ...seasonOngoingShowrooms.map((showroom) =>
        columnHelper.group({
          meta: {
            cellClassName: "",
            headerClassName: "bg-primaryElectricBlue text-white rounded-t-lg",
          },
          header: showroom.name,
          size: 2,
          columns: [
            columnHelper.display({
              meta: {
                cellClassName: "border-l border-primaryElectricBlue",
                headerClassName:
                  "bg-primaryLightElectricBlue border-l border-primaryElectricBlue",
              },
              header: "Invitation",
              size: 1,
              id: `invitation-${showroom.id}`,
              cell: createInvitationCellRenderer(showroom.id),
            }),
            columnHelper.display({
              meta: {
                cellClassName: "",
                headerClassName: "bg-primaryLightElectricBlue",
              },
              id: `email-${showroom.id}`,
              header: "Email",
              size: 1,
              cell: createEmailCellRenderer(showroom.id),
            }),
            columnHelper.display({
              meta: {
                cellClassName: "",
                headerClassName: "bg-primaryLightElectricBlue",
              },
              id: `status-${showroom.id}`,
              header: "Status",
              size: 1,
              cell: createStatusesCellRenreder(showroom),
            }),
            columnHelper.display({
              meta: {
                cellClassName: "border-r border-primaryElectricBlue",
                headerClassName:
                  "bg-primaryLightElectricBlue border-r border-primaryElectricBlue",
              },
              id: `link-${showroom.id}`,
              header: "",
              size: 1,
              cell: createLinkCellRenderer(showroom.id),
            }),
          ],
        }),
      ),
    ],
    [columnHelper, seasonOngoingShowrooms],
  );
  if (
    fetchAllOngoingShowroomsStatus === "success" &&
    allOngoingShowrooms.length === 0
  ) {
    return <NoOngoingShowroom />;
  }

  return (
    <LoadingFetch
      status={invitationFetchStatus}
      error="Could not load invitation statuses"
    >
      <div className="space-y-4 p-4">
        <div className="flex gap-4 items-baseline">
          <span className="text-2xl">{pt("page-title")}</span>
          <SingleSelect
            options={allOngoingShowrooms
              .map(({ season: s, year: y }) => SeasonYear.toString(s, y))
              .filter(arrayFilterUnique)
              .map((v) => ({
                label: v,
                value: v,
              }))}
            value={{
              value: SeasonYear.toString(season, year),
              label: SeasonYear.toString(season, year),
            }}
            onChange={(o) =>
              o ? setSeasonYear(SeasonYear.fromString(o.value)) : undefined
            }
          />
        </div>
        {/** filters */}
        <div className="grid grid-cols-8 items-center gap-2">
          <div className="col-span-3">
            <WildcardFilter
              value={filterState.values.wildcard}
              onChange={(v) => filterState.setWildcard(v)}
            />
          </div>
          <Button
            className="justify-center"
            theme="PRIMARY"
            onClick={() => setShowFilters(!showFilters)}
          >
            {pt(showFilters ? "hide-filters" : "show-filters")}
            {filterState.count > 0 && (
              <Tag type="number" theme="PRIMARY-REVERSED" size="sm">
                {filterState.count}
              </Tag>
            )}
          </Button>

          {/** action buttons */}
          <div className="col-span-4 flex justify-end gap-4 items-center">
            <span className="text-right">
              {selectedContactIds.length > 0
                ? pt("{{count}}-contacts-selected", {
                    count: selectedContactIds.length,
                  })
                : null}
            </span>
            <div className="flex items-center gap-4">
              <SendFollowupsCTA
                contactIds={selectedContactIds}
                showrooms={seasonOngoingShowrooms}
              />
              <SendInvitationsCTA
                contactIds={selectedContactIds}
                showrooms={seasonOngoingShowrooms}
              />
            </div>
          </div>
        </div>

        <Transition
          show={showFilters}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="transition-opacity duration-200"
        >
          <div className="flex flex-wrap gap-4">
            <InvitationStatusFilter
              onChange={(v) => {
                filterState.setInvitationStatus(v.invitationStatus);
                filterState.setShowroomId(v.showroomId);
              }}
              values={filterState.values}
              showrooms={seasonOngoingShowrooms}
            />
            <AccountStatusFilter
              onChange={(v) => {
                filterState.setAccountStatus(v.accountStatus);
                filterState.setCollectionId(v.collectionId);
              }}
              values={filterState.values}
              collections={collections}
            />
            <MarketFilter
              values={filterState.values}
              onChange={(v) => filterState.setMarket(v.market)}
            />
            <PortfolioFilter
              values={filterState.values}
              portfolios={contactsWithAggregation
                .flatMap((c) => c.portfolios)
                .filter(arrayFilterDuplicateIds)}
              onChange={(v) => filterState.setPortfolioId(v.portfolioId)}
            />
            <KeyAccountFilter
              values={filterState.values}
              onChange={filterState.setIsKeyAccountFilter}
            />
          </div>
        </Transition>

        {/** "TABLE" */}

        <MRTable<ContactRow>
          initialPageSize={100}
          key={SeasonYear.toString(season, year)}
          data={filteredContacts}
          columns={columns}
          rowSelectionOption={{
            canBeSelected: true,
            initialSelection: selectedContactIds,
            onSelectionChange: setSelectedContactIds,
          }}
          paginationPlacement="bottom"
        />
      </div>
    </LoadingFetch>
  );
}
